/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Image, Box, Badge, Spacer, Flex, IconButton,
  Button,
  VStack,
} from '@chakra-ui/react';
import { FiMapPin } from 'react-icons/fi';
import { withApiContext } from '../../Context/ApiContext';
import propertyTester from '../../Utils/PropertyTester';

function getModeTriangle(mode) {
  const triangleStyle = {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 25px 25px 0',
    borderColor: `transparent ${(mode === 'anyone' ? '#1EC655' : '#f2eb21')} transparent transparent`,
    position: 'absolute',
    right: 0,
    top: 0,
  };

  return <div style={triangleStyle} title={`místo se nachází v režimu ${mode}`} />;
}

function ListItem(props) {
  const noPhotoUrl = 'https://res.cloudinary.com/hz1vl72op/image/upload/v1632089452/static/no_photo_new_mxiisc.png';
  const {
    name, photo, id, equipments, place, mode, preReservation: deskPreReservation,
  } = props.data;
  const { preReservation, reserveDesk, cancelReservation } = props.apiContext;
  const {
    isReserved, showDetail, openDialog, showPrereservation,
  } = props;
  const photoUrl = propertyTester(() => photo.formats.medium.url) || noPhotoUrl;
  const preReservationEnable = !preReservation && !deskPreReservation;

  return (
    <Box
      pos="relative"
      maxW="261px"
      minW="200px"
      // height="440px"
      borderRadius="md"
      overflow="hidden"
      boxShadow="md"
      bg="white"
      cursor="pointer"
      transition="transform 300ms"
      _hover={{
        boxShadow: 'lg',
        transform: 'scale(1.05)',
      }}
    >

      <Image src={`${photoUrl}`} alt="" />
      {getModeTriangle(mode)}
      <VStack
        position="absolute"
        left="10px"
        top="10px"
        display="flex"
        alignItems="baseline"
        flexWrap="wrap"
      >
        {equipments && equipments.map((equipment) => (
          <Badge
            borderRadius="1"
            colorScheme="deskeeYellow"
            key={`eq-${name}-${equipment.id}`}
          >
            {equipment.desc}
          </Badge>
        ))}
      </VStack>
      <Box p="3">
        <Box
          fontWeight="semibold"
          lineHeight="tight"
          isTruncated
          fontSize="1.2rem"
          textTransform="uppercase"
        >
          {name}
        </Box>

        {!isReserved && (
          <Box mt="4" textAlign="center">
            <VStack>
              <Button
                colorScheme="deskeeGreen"
                size="md"
                onClick={async () => {
                  await reserveDesk(id).catch((e) => {
                    openDialog(
                      {
                        type: 'error',
                        message: e.response.data.message,
                      },
                    );
                  });
                }}
              >
                REZERVOVAT DNES
              </Button>
              <Button
                variant="solid"
                colorScheme={preReservationEnable ? 'purple' : 'gray'}
                size="sm"
                aria-label="Open prereservation"
                // leftIcon={<FiClock />}
                onClick={showPrereservation}
                disabled={!preReservationEnable}
              >
                REZERVOVAT NA ZÍTRA
              </Button>
            </VStack>
          </Box>
        )}

        {isReserved && (
          <Box mt="4" textAlign="center">
            <VStack>
              <Button
                colorScheme="red"
                size="lg"
                onClick={() => {
                  cancelReservation();
                }}
              >
                ZRUŠIT REZERVACI
              </Button>
              <Button
                size="sm"
                variant="solid"
                colorScheme={preReservationEnable ? 'purple' : 'gray'}
                aria-label="Open prereservation"
                // icon={<FiClock />}
                onClick={showPrereservation}
                disabled={!preReservationEnable}
              >
                REZERVOVAT NA ZÍTRA
              </Button>
            </VStack>
          </Box>
        )}

        <Flex mt="4" align="center">
          <Box>
            <IconButton
              icon={<FiMapPin />}
              size="sm"
              onClick={showDetail}
            />
          </Box>
          <Spacer />
          <Box
            p="2"
            textTransform="uppercase"
          >
            {(place.floor === 0 ? 'Přízemí' : `${place.floor}. patro`)}
            {' '}
            -
            {' '}
            {place.shortTitle}
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}

export default withApiContext(ListItem);
