import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  VStack,
  Text,
  Icon,
} from '@chakra-ui/react';
import { FiAlertTriangle, FiCheck } from 'react-icons/fi';

function Dialog(props) {
  const { isOpen, onClose } = props;
  const { type, message } = props.data || {};

  if (!type && !message) return null;

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent m="5">
        <AlertDialogHeader />
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <VStack>
            { type === 'error'
                && <Icon as={FiAlertTriangle} boxSize="4rem" color="red" />}
            { type === 'success'
                && <Icon as={FiCheck} boxSize="4rem" color="green" />}
            <Text pt="2rem">{message || (type === 'error' && !message ? 'Nastala chyba' : '')}</Text>
          </VStack>
        </AlertDialogBody>
        <AlertDialogFooter />
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default Dialog;
