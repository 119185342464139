import { extendTheme } from "@chakra-ui/react"
const theme = extendTheme({
  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: "linear-gradient(to top, #fdd74c, #f9c403)",
      },
      // styles for the `a`
      a: {
        color: "teal.500",
        _hover: {
          textDecoration: "underline",
        },
      },
    },
  },
  fonts: {
    heading: "akrobat",
    body: "akrobat",
  },
  colors: {
    /*
    deskeeGreen: 
    {
      50: '#e6f8e9',
      100: '#cce2cf',
      200: '#afcfb5',
      300: '#91ba99',
      400: '#72a67e',
      500: '#598d67',
      600: '#446d4d',
      700: '#2f4e34',
      800: '#1a301c',
      900: '#021201',
    },*/
    deskeeGreen: 
    {
      50: '#dbf7ff',
      100: '#b4e9f9',
      200: '#89dcf3',
      300: '#5ed4ed',
      400: '#37cee7',
      500: '#22bace',
      600: '#1295a1',
      700: '#036474',
      800: '#003746',
      900: '#00121a',
    },
    deskeeOrange:  
    {
      50: '#fff7de',
      100: '#fdebb2',
      200: '#fae184',
      300: '#f9cb55',
      400: '#f7af28',
      500: '#dd8813',
      600: '#ac5e0b',
      700: '#7b3b05',
      800: '#4a1d00',
      900: '#1b0700',
    },
    deskeeYellow:
    {
      50: '#ffffdb',
      100: '#fff9ad',
      200: '#fdf07e',
      300: '#fde34d',
      400: '#fcd31c',
      500: '#e3b303',
      600: '#b09700',
      700: '#7e7400',
      800: '#4d4b00',
      900: '#1c1b00',
    }
  },
})
export default theme

/**
 * dark green #073E2E
 * green #60986F
 * light green #91AE8D
 * orange #FADF7A
 * yellow #F9C403
 */