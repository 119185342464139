import { Center, Image, Text, VStack, keyframes, usePrefersReducedMotion } from '@chakra-ui/react';
import logoImg from '../Images/logo-simple.svg';

export const Loader = props => {

  const move = keyframes`
      0% { transform: rotate(0deg); }
      15% { transform: rotate(-20deg); }
      30% { transform: rotate(20deg); }
      45% { transform: rotate(0deg); }
    `;
    const prefersReducedMotion = usePrefersReducedMotion();

    const animation = prefersReducedMotion
    ? undefined
    : `${move} infinite 2s ease`;

  return (
      <Center 
        w="100vw" 
        h="100vh" 
        pos="absolute" 
        left="0" 
        t="0" 
        bg="linear-gradient(to top, #fdd74c, #f9c403)" 
        zIndex="1000"
        opacity="0.9"
      >
        <VStack>
        <Image 
              boxSize="140px"
              align="center"
              objectFit="contain"
              src={logoImg}
              alt="Deskee - office desk reservation system" 
              animation={animation}
          />
        <Text fontSize="36px" fontWeight="bold">DESKEE</Text>
        <Text>Načítám ...</Text>
        </VStack>
      </Center>
  );
}