import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { withLoginContext } from '../Context/LoginContext';
import { LOGIN } from '../routes';
import { withRouter } from "react-router";


class PrivateRoute extends Component {

  render() {
    const { isLogged } = this.props.loginContext;
    return (
      <Route
        render={({ location }) =>
          isLogged() ? (
            this.props.children
          ) : (
            <Redirect
              to={{
                pathname: LOGIN,
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }
}

export default withRouter(withLoginContext(PrivateRoute));