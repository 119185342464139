import React, { Component } from 'react';
import { 
  Stack,
  Input, 
  Text, 
  Center, 
  Button,
  InputGroup,
  InputLeftElement,
  Heading,
  VStack,
} from '@chakra-ui/react';
import { withLoginContext } from '../Context/LoginContext';
import { FiUser, FiLock, FiEye, FiEyeOff, FiMail } from "react-icons/fi";
import { Redirect, Link } from 'react-router-dom';
import { HOMEPAGE } from '../routes';

class Register extends Component {
  constructor(props) {
    super(props);
    this.allowedDomains = ['etnetera.cz', 'etnetera.eu', 'motion.cz', 'activate.cz'];

    this.state = {
      username: '',
      email: '',
      pass: '',
      repass: '',
      showPass: false,
      registerError: false,
      registerDone: false,
    }

    this.register = this.register.bind(this);
  }

  handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState({
      [name]: value
    });
  }

  togglePassShow = () => {
    const { showPass } = this.state;
    this.setState({showPass: !showPass});
  }

  checkAllowedEmailDomains = (email) => {
    const allowedDomains = [
      'etnetera.cz', 
      'etnetera.eu', 
      'motion.cz', 
      'activate.cz',
      'greencode.cz',
      'fragile.cz'
    ];

    let result = false
    allowedDomains.forEach((domain) => {
      if (email.includes(domain)) {
        result = true;
      }
    });

    return result;
  }

  checkBeforeSend = () => {
    const { pass, email, username, repass } = this.state;

    if (email === '' || !email.includes('@')) {
      this.setState({ registerError: 'Zdá se, že není správně vyplněn email' });
      return false;
    }

    if (!this.checkAllowedEmailDomains(email)) {
      this.setState({ registerError: 'Doména emailu není mezi povolenými' });
      return false;
    }

    if (!username.includes(' ')) {
      this.setState({ registerError: 'Zdá se, že není správně vyplněno pole jméno a přijmení' });
      return false;
    }

    if (pass.length < 6) {
      this.setState({ registerError: 'Heslo musí být dlouhé min. 6 znaků' });
      return false;
    }

    if (pass !== repass) {
      this.setState({ registerError: 'Heslo a heslo pro ověření se neshodují' });
      return false;
    }

    return true;
  }

  register = async () => {
    const { pass, email, username } = this.state;
    const { loginContext } = this.props;

    if (this.checkBeforeSend()) {
      const register = await loginContext.register(username, email, pass);
      if (register.status === 'done') {
        this.setState({
          username: '',
          pass: '',
          repass: '',
          showPass: false,
          registerError: false,
          registerDone: true,
        });
      } else {
        this.setState({ registerError: register.message });
      }
    }
  }

  render() {
    const { username, email, pass, repass, showPass, registerDone, registerError } = this.state;
    const { location } = this.props;
    const { isLogged } = this.props.loginContext;

    if (isLogged()) {
      return <Redirect
        to={{
          pathname: HOMEPAGE,
          state: { from: location }
        }}
      />;
    }

    if (registerDone) {
      return (
        <Center>
          <VStack spacing="20px">
          <Heading as="h1" size="4xl" isTruncated>
            Díky!
          </Heading>
          <Text fontSize="2xl">Na email <strong>{email}</strong> ti byl odeslán potvrzovací email.</Text>
          <Text fontSize="1xl">Prosím nezapomeň zkontrolovat, zda se ti nezatoulal do složky SPAM</Text>
          </VStack>
        </Center>
      );
    }

    return (
      <form>
      <Center>
        <Stack spacing={4}>
          <Heading 
              as="h1" 
              size="md" 
              textAlign="center"
              textTransform="uppercase"
              fontWeight="300"
            >
              Registrace
          </Heading>
          <InputGroup size="lg">
            <InputLeftElement
              pointerEvents="none"
              children={<FiUser color="gray.300" />}
            />
            <Input 
              variant="filled" 
              name="username"
              type="text"
              placeholder="jméno a přijmení"
              onChange={this.handleChange}
              value={username}
              autoComplete="fullname"
            />
          </InputGroup>
          <InputGroup size="lg">
            <InputLeftElement
              pointerEvents="none"
              children={<FiMail color="gray.300" />}
            />
            <Input 
              variant="filled" 
              name="email"
              type="email"
              placeholder="email"
              onChange={this.handleChange}
              value={email}
              autoComplete="off"
            />
          </InputGroup>
          <InputGroup size="lg">
            {pass === '' && <InputLeftElement
              children={<FiLock color="gray.300" />}
            />}
            {pass !== '' && <InputLeftElement 
              children={(!showPass ? <FiEye color="gray.300" /> : <FiEyeOff color="gray.300" />)}
              onClick={this.togglePassShow}
            />}
            <Input 
              placeholder="heslo"
              variant="filled"
              type={showPass ? 'text' : 'password'} 
              name="pass"
              value={pass}
              onChange={this.handleChange}
              autoComplete="new-password"
            />
          </InputGroup>
          <InputGroup size="lg">
            <InputLeftElement
              children={<FiLock color="gray.300" />}
            />
            <Input               
              placeholder="heslo znovu"
              variant="filled"
              type={showPass ? 'text' : 'password'} 
              name="repass"
              value={repass}
              onChange={this.handleChange}
              autoComplete="new-password"
            />
          </InputGroup>
          <Button textTransform="uppercase" colorScheme="deskeeGreen" onClick={this.register}>Registrovat</Button>
        </Stack>
      </Center>
      {registerError && <Text align="center" color="red" mt="30px">{registerError}</Text>}
        <Link to={HOMEPAGE}>
          <Text textAlign="center" mt="20px" textDecor="underline">
            Již účet mám
          </Text>
        </Link>
      </form>
    );
  }

}

export default withLoginContext(Register);

/**
 * photo
 * name
 * mode
 * description
 * location
 */