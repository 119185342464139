/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import {
  Wrap, WrapItem, Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuOptionGroup,
  IconButton,
  MenuDivider, Text, Icon, Center, useDisclosure, VStack,
  Container, Button, Box,
} from '@chakra-ui/react';
import {
  FiFilter, FiRefreshCw, FiEyeOff, FiX,
} from 'react-icons/fi';
import { withLoginContext } from '../Context/LoginContext';
import ListItem from '../Components/Desk/ListItem';
import { withApiContext } from '../Context/ApiContext';
import InfoModal from '../Components/Desk/InfoModal';
import ErrorModal from '../Components/Dialog';
import PreReservationModal from '../Components/Desk/PreReservationModal';
import PreReservationItem from '../Components/Desk/PreReservationItem';
import TypeMenu from '../Components/TypeMenu';

function MainPage(props) {
  const {
    availableFilters,
    reservation,
    preReservation,
    direct,
    removeDirectLink,
    desks,
    reloadDesk,
    filterChange,
    resetFilters,
    isActiveFilters,
    activeFilters,
  } = props.apiContext;

  const [deskDetail, setDeskDetail] = useState(null);
  const [dialog, setDialog] = useState(null);
  const [desksByPlace, setDesksByPlace] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDialog,
    onOpen: onOpenDialog,
    onClose: onCloseDialog,
  } = useDisclosure();

  const {
    isOpen: isOpenPrereservation,
    onOpen: onOpenPrereservation,
    onClose: onClosePrereservation,
  } = useDisclosure();

  const openDeskDetail = (deskId) => {
    const res = reservation || direct || {};
    const desk = res.desk || (desks.find((d) => d.id === deskId));
    setDeskDetail(desk);
    onOpen();
  };

  const openDeskPrereservation = (deskId) => {
    const res = reservation || direct || {};
    const desk = res.desk || (desks.find((d) => d.id === deskId));
    setDeskDetail(desk);
    onOpenPrereservation();
  };

  const openDialog = (dialogData) => {
    setDialog(dialogData);
    onOpenDialog();
  };

  useEffect(() => {
    if (!desks) return;

    const prepareDesksByPlace = {};
    desks.forEach((desk) => {
      if (!prepareDesksByPlace[desk.place.id]) {
        prepareDesksByPlace[desk.place.id] = {
          shortTitle: desk.place.shortTitle,
          floor: desk.place.floor,
          desks: [],
        };
      }

      prepareDesksByPlace[desk.place.id].desks.push(desk);
    });
    setDesksByPlace(prepareDesksByPlace);
  }, [desks]);

  if (reservation) {
    const autoDeleteReservationTime = (new Date().getHours() < 18 ? 'v 18:00' : 've 23:59');

    return (
      <Container maxW="100%">
        <InfoModal isOpen={isOpen} onClose={onClose} desk={deskDetail} />
        <PreReservationModal
          isOpen={isOpenPrereservation}
          onClose={onClosePrereservation}
          desk={deskDetail}
          openDialog={openDialog}
        />
        <ErrorModal
          isOpen={isOpenDialog}
          onClose={onCloseDialog}
          data={dialog}
        />

        <Box d="flex" justifyContent="center" width="100%" mb="5">
          <TypeMenu />
        </Box>

        {preReservation && <PreReservationItem data={preReservation} />}

        <Text
          fontSize="3xl"
          mb="5"
          borderBottom={(preReservation ? '1px solid black' : '')}
          textAlign={['center', 'left', 'left', 'left']}
          width="100%"
        >
          Tvoje dnešní rezervace
        </Text>
        <Wrap spacing="30px" justify={['center', 'center', 'center', 'flex-start']}>
          <WrapItem key="dsk-reserved">
            <ListItem
              isReserved
              data={reservation.desk}
              showDetail={
                () => {
                  openDeskDetail(reservation.desk.id);
                }
              }
              showPrereservation={
                () => {
                  openDeskPrereservation(reservation.desk.id);
                }
              }
            />
          </WrapItem>
        </Wrap>
        <Text
          align="center"
          textTransform="uppercase"
          mt="2rem"
          fontSize="xl"
        >
          Rezervace se automaticky ukončí <strong>{autoDeleteReservationTime}</strong>
        </Text>

      </Container>
    );
  }

  if (direct) {
    return (
      <Container maxW="100%">
        <InfoModal isOpen={isOpen} onClose={onClose} desk={deskDetail} />
        {direct.desk && (
        <Text
          fontSize="3xl"
          mb="4"
          textTransform="uppercase"
          align={{ base: 'center', md: 'left' }}
        >
          Tvůj scan
        </Text>
        )}
        {direct.desk
          && (
          <>
            <Wrap spacing="30px" justify={['center', 'center', 'center', 'flex-start']}>
              <WrapItem key="dsk-reserved">
                <ListItem
                  data={direct.desk}
                  showDetail={
                    () => {
                      openDeskDetail(direct.desk.id);
                    }
                  }
                  showPrereservation={
                    () => {
                      openDeskPrereservation(direct.desk.id);
                    }
                  }
                />
              </WrapItem>
            </Wrap>
            <Center mt="2rem">
              <Button onClick={removeDirectLink}>Zobrazit všechny dostupné stoly</Button>
            </Center>
          </>
          )}
        {!direct.desk
          && (
          <Center textAlign="center">
            <VStack spacing={4}>
              <Icon
                as={FiEyeOff}
                boxSize="4rem"
                color="#036474"
              />
              <Text
                size="md"
                color="#036474"
              >
                Skenovaný stůl není k dispozici

              </Text>
              <Button
                onClick={removeDirectLink}
                mt="2rem"
              >
                Zobrazit všechny dostupné stoly

              </Button>
            </VStack>
          </Center>
          )}
      </Container>
    );
  }

  return (
    <Container maxW="100%">
      <InfoModal isOpen={isOpen} onClose={onClose} desk={deskDetail} />
      <PreReservationModal
        isOpen={isOpenPrereservation}
        onClose={onClosePrereservation}
        desk={deskDetail}
        openDialog={openDialog}
      />
      <ErrorModal
        isOpen={isOpenDialog}
        onClose={onCloseDialog}
        data={dialog}
      />

      <Box d="flex" justifyContent="center" width="100%" mb="5">
        <TypeMenu />
      </Box>

      {preReservation && <PreReservationItem data={preReservation} />}
      {preReservation && (
      <Text
        fontSize="3xl"
        mb="5"
        borderBottom="1px solid black"
        textAlign={['center', 'left', 'left', 'left']}
        width="100%"
      >
        Aktuálně volné stoly
      </Text>
      )}
      <Box d="flex" justifyContent={['center', 'center', 'center', 'flex-end']} width="100%" mb="5">
        <Box d="flex" justifyContent={['center', 'center', 'center', 'flex-end']} width="100%" mb="5">
          <IconButton
            aria-label="Logout"
            icon={<FiRefreshCw />}
            onClick={reloadDesk}
            boxShadow="md"
            marginRight="15px"
          />
          <Menu
            closeOnSelect={false}
            placement="bottom-end"
          >
            <MenuButton as={Button} colorScheme={(isActiveFilters() ? 'deskeeGreen' : 'gray')} shadow="md">
              <Center>
                <Icon as={FiFilter} />
                <Text ml="2">FILTRY</Text>
              </Center>
            </MenuButton>
            <MenuList minWidth="240px" shadow="lg">
              {availableFilters.floors
            && (
            <MenuOptionGroup
              title="Patro"
              type="checkbox"
              onChange={(e) => {
                filterChange('floors', e);
              }}
              value={activeFilters.floors}
            >
              {availableFilters.floors.sort().map((floor, i) => (
                <MenuItemOption
                  value={floor}
                  key={`floor-${i}`}
                >
                  {(floor === 0 ? 'Přízemí' : `${floor}.`)}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
            )}
              {availableFilters.groups && availableFilters.groups.length > 0
            && (
            <>
              <MenuDivider />
              <MenuOptionGroup
                title="Skupiny"
                type="checkbox"
                onChange={(e) => {
                  filterChange('groups', e);
                }}
                value={activeFilters.groups}
              >
                {availableFilters.groups.map((group) => (
                  <MenuItemOption
                    value={group.id}
                    key={`group-${group.id}}`}
                    isDisabled={activeFilters.only.includes('anyone')}
                  >
                    {group.name}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
              <MenuDivider />
              <MenuOptionGroup
                type="checkbox"
                onChange={(e) => {
                  filterChange('only', e);
                }}
                value={activeFilters.only}
              >
                <MenuItemOption
                  value="anyone"
                  key="only-anyone-mode"
                >
                  Pouze ANYONE
                </MenuItemOption>
              </MenuOptionGroup>
            </>
            )}
              <MenuDivider />
              {availableFilters.equipments.length > 0 && availableFilters.floors
            && (
            <MenuOptionGroup
              title="Vybavení"
              type="checkbox"
              onChange={(e) => {
                filterChange('equipments', e);
              }}
              value={activeFilters.equipments}
            >
              {availableFilters.equipments.map((equipment) => (
                <MenuItemOption
                  value={equipment.id}
                  key={`equip-${equipment.id}`}
                >
                  {equipment.desc}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
            )}
              {isActiveFilters()
              && (
              <>
                <MenuDivider />
                <MenuItem
                  icon={<FiX />}
                  onClick={() => {
                    resetFilters();
                  }}
                  color="red"
                >
                  Zrušit filtry
                </MenuItem>
              </>
              )}
            </MenuList>
          </Menu>
        </Box>
      </Box>
      {desksByPlace && Object.keys(desksByPlace).map((placeId, i) => {
        const place = desksByPlace[placeId];
        return (
          <Box key={`dbp-${i}`} w="100%" mb="10">
            <Text
              key={`plc-${i}`}
              fontWeight="light"
              lineHeight="tight"
              isTruncated
              fontSize="1rem"
              textTransform="uppercase"
              textAlign={['center', 'left', 'left', 'left']}
              mb="5"
            >
              {place.shortTitle} - {(place.floor === 0 ? 'Přízemí' : `${place.floor}. patro`)}
            </Text>
            <Wrap spacing="30px" justify={['center', 'center', 'center', 'flex-start']} key={`wdsk-${i}`}>
              {place.desks && place.desks.map((desk, o) => (
                <WrapItem key={`dsk-${i}-${o}`}>
                  <ListItem
                    data={desk}
                    onOpen={onOpen}
                    openDialog={openDialog}
                    showDetail={
                () => {
                  openDeskDetail(desk.id);
                }
              }
                    showPrereservation={
                () => {
                  openDeskPrereservation(desk.id);
                }
              }
                  />
                </WrapItem>
              ))}
            </Wrap>
          </Box>
        );
      })}

      {((!desks || desks.length < 1) && isActiveFilters())
          && (
          <Container maxW="100%">
            <Center textAlign="center">
              <VStack spacing={4}>
                <Icon as={FiEyeOff} boxSize="4rem" color="#036474" />
                <Text size="md" color="#036474">Žádné výsledky pro zvolený filtr</Text>
              </VStack>
            </Center>
          </Container>
          )}
      {((!desks || desks.length < 1) && !isActiveFilters())
          && (
          <Container maxW="100%">
            <Center textAlign="center">
              <VStack spacing={4}>
                <Icon as={FiEyeOff} boxSize="4rem" color="#036474" />
                <Text size="md" color="#036474">Žádné stoly k dispozici</Text>
              </VStack>
            </Center>
          </Container>
          )}
    </Container>
  );
}

export default withLoginContext(withApiContext(MainPage));

/**
 * photo
 * name
 * mode
 * description
 * location
 */
