import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  VStack,
  Center,
  Box,
  Text,
} from '@chakra-ui/react';
import { QRCodeSVG } from 'qrcode.react';
import { withLoginContext } from '../../Context/LoginContext';

function PaymentModal(props) {
  if (!props.parkPlace) return null;
  const { isOpen, onClose } = props;
  const { parkPlace } = props;
  const { shortTitle } = parkPlace.place;
  const { price, name } = parkPlace;
  const { username } = props.loginContext;
  const paymentAccount = '287352504/0300';
  const paymentAccountIban = 'CZ6503000000000287352504';
  const paymentData = `SPD*1.0*ACC:${paymentAccountIban}*AM:${price}.00*CC:CZK*MSG:${username} - ${name} - ${new Date().toLocaleDateString('cs-CZ')}`;

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size="xl" m="10px">
      <ModalOverlay />
      <ModalContent m="5">
        <ModalHeader textTransform="uppercase">
          Platba za parkování
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Center>
            <VStack spacing={4}>
              <Box mb="2">
                <Text
                  align="center"
                  fontWeight="semibold"
                  lineHeight="tight"
                  isTruncated
                  fontSize="1.8rem"
                  textTransform="uppercase"
                >
                  {parkPlace.name}
                </Text>
                <Text align="center" textTransform="uppercase">
                  {shortTitle}
                </Text>
              </Box>
              <QRCodeSVG value={paymentData} />
              <VStack align="flex-start" pt="2">
                <Text>
                  <b>Číslo účtu:</b> {paymentAccount}
                </Text>
                <Text>
                  <b>Částka: </b>{price} Kč
                </Text>
                <Text>
                  <b>Zpráva pro příjemce:</b> {username} - {name} - {new Date().toLocaleDateString('cs-CZ')}
                </Text>
              </VStack>
            </VStack>
          </Center>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
}

export default withLoginContext(PaymentModal);
