/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { Component } from 'react';
import axios from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import { withRouter } from 'react-router';
import qs from 'qs';
import { withLoginContext } from './LoginContext';
import log from '../Utils/Logger';

const { Provider, Consumer } = React.createContext();

class ApiContextProvider extends Component {
  constructor(props) {
    super(props);
    this.initialized = false;
    this.autoRefreshIntervalMs = 60 * 1000;
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.autoRefreshInterval = null;

    this.state = {
      loading: true,
      autorefresh: false,
      reservation: null,
      preReservation: null,
      parkingReservation: null,
      parkingPreReservation: null,
      desks: [],
      parkPlaces: [],
      direct: null,
      availableFilters: {
        floors: [],
        equipments: [],
        groups: [],
        only: [],
      },
      activeFilters: {
        floors: [],
        groups: [],
        equipments: [],
        only: [],
      },
    };
  }

  async componentDidMount() {
    // const { loginContext } = this.props;
    // await this.getUserReservation();
    // const desks = await this.getDesks();
    // this.setState({desks});

    if (process.env.REACT_APP_AUTOREFRESHDESK) {
      // eslint-disable-next-line react/no-unused-class-component-methods
      this.autoRefreshInterval = setInterval(async () => {
        const { jwt } = this.props.loginContext;
        if (jwt) {
          this.setAutorefreshNotif(true);
          await this.reloadDesk();
          await this.reloadParkPlaces();
          await this.getUserPreReservation();
          await this.getUserReservation();
          await this.getUserParkPlaceReservation();
          await this.getUserParkPlacePreReservation();
          this.setAutorefreshNotif(false);
        }
      }, this.autoRefreshIntervalMs);
    }

    setTimeout(() => {
      this.setState({ loading: false });
    }, 2500);
  }

  async componentDidUpdate(prevProps) {
    const { loginContext } = this.props;

    if (loginContext.loaded && prevProps.loginContext.jwt !== loginContext.jwt && !this.initialized) {
      this.initialized = true;
      this.setLoading(true);

      const { location, history } = this.props;
      const { from } = location.state || { };
      if (from) {
        history.replace(from);
      }

      await this.getUserReservation().then(async () => {
        if (!this.state.reservation) {
          const desks = await this.getDesks();
          const availableFilters = this.getFilters(desks);
          const direct = this.checkDirectLink(desks);
          this.setState({
            availableFilters,
            desks,
            direct,
          });
        }
      });

      await this.getUserPreReservation();

      await this.getUserParkPlaceReservation().then(async () => {
        if (!this.state.parkingReservation) {
          const parkPlaces = await this.getParkPlaces();
          this.setState({ parkPlaces });
        }
      });

      await this.getUserParkPlacePreReservation();

      this.setLoading(false);
    }
  }

  isActiveFilters = () => {
    const {
      floors, equipments, groups, only,
    } = this.state.activeFilters;
    const result = floors.length > 0 || equipments.length > 0 || groups.length > 0 || only.length > 0;
    return result;
  };

  getAuthorizationHeader = () => {
    const { jwt } = this.props.loginContext;
    return {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };
  };

  checkDirectLink = (desks) => {
    const { location } = this.props;
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    const { direct: directParamId } = params;

    if (directParamId) {
      const desk = desks.filter((_desk) => _desk.directId === directParamId)[0];
      return {
        desk,
      };
    }

    return null;
  };

  removeDirectLink = () => {
    const { direct } = this.state;
    if (direct) {
      this.setState({ direct: null });
      const { history } = this.props;
      history.replace({ from: { pathname: '/' } });
    }
  };

  getFilters = (desks) => {
    const { availableFilters } = this.state;
    log('[api-context] getFilters desks', desks);
    if (!desks) return availableFilters;

    const { userGroups } = this.props.loginContext;

    desks.forEach((desk) => {
      const { floor } = desk.place;
      if (!availableFilters.floors.includes(floor)) {
        availableFilters.floors.push(floor);
      }

      const { equipments } = desk;
      equipments.forEach((equipment) => {
        if (!availableFilters.equipments[equipment.id]) {
          availableFilters.equipments[equipment.id] = {
            id: equipment.id,
            desc: equipment.desc,
          };
        }
      });

      userGroups.forEach((group) => {
        if (!availableFilters.groups[group.id]) {
          availableFilters.groups[group.id] = {
            id: group.id,
            name: group.name,
          };
        }
      });
    });

    availableFilters.floors.sort();
    log('[api-context] setFilters return', availableFilters);

    return availableFilters;
  };

  filterChange = (type, value) => {
    const { activeFilters } = this.state;
    activeFilters[type] = value;

    log('[api-context] filterChange', type, value, activeFilters);
    this.setState({ activeFilters });

    this.filter();
  };

  resetFilters = async () => {
    const { activeFilters } = this.state;
    Object.keys(activeFilters).forEach((filterKey) => {
      activeFilters[filterKey] = [];
    });
    log('[api-context] reset filters');
    this.setState({ activeFilters });

    this.filter();
  };

  getDesks = () => {
    const { isLogged } = this.props.loginContext;
    if (isLogged) {
      return axios.get(`${process.env.REACT_APP_API_URL}/desks/available`, this.getAuthorizationHeader()).then((res) => {
        const { data } = res;
        log('[api-context] load desks', data);
        // this.setState({ desks: data });
        return data;
      }).catch((e) => {
        console.error('[api-context] load desks error', e);
        return null;
      });
    }

    return null;
  };

  getParkPlaces = () => {
    const { isLogged } = this.props.loginContext;
    if (isLogged) {
      return axios.get(`${process.env.REACT_APP_API_URL}/park-places/available`, this.getAuthorizationHeader()).then((res) => {
        const { data } = res;
        log('[api-context] load park places', data);
        return data;
      }).catch((e) => {
        console.error('[api-context] load park places error', e);
        return null;
      });
    }

    return null;
  };

  /*
  getDesk = (id) => {
    const { isLogged } = this.props.loginContext;
    if (isLogged) {
      return axios.get(`${process.env.REACT_APP_API_URL}/desks/${id}`, this.getAuthorizationHeader()).then((res) => {
        const { data } = res;
        log('[api-context] load desk', data);
        return data;
      }).catch((e) => {
        console.error('[api-context] load desk error', e);
      });
    }
  };

  getPlace = (id) => {
    const { isLogged } = this.props.loginContext;
    if (isLogged) {
      return axios.get(`${process.env.REACT_APP_API_URL}/places/${id}`, this.getAuthorizationHeader()).then((res) => {
        const { data } = res;
        log('[api-context] load place', data);
        return data;
      }).catch((e) => {
        console.error('[api-context] load place error', e);
      });
    }
  };
  */

  getUserReservation = async () => {
    log('[api-context] getAuthorizationHeader', this.props.loginContext);
    await axios.get(`${process.env.REACT_APP_API_URL}/reservations/user`, this.getAuthorizationHeader()).then(async (res) => {
      log('[api-context] load user reservation', res);
      const reservation = res.data;

      this.setState({ reservation });
    }).catch((e) => {
      console.error(e);
    });
  };

  getUserPreReservation = async () => {
    log('[api-context] getAuthorizationHeader', this.props.loginContext);
    await axios.get(`${process.env.REACT_APP_API_URL}/pre-reservations/user`, this.getAuthorizationHeader()).then(async (res) => {
      log('[api-context] load user pre-reservation', res);
      const preReservation = res.data;
      this.setState({ preReservation });
    }).catch((e) => {
      console.error(e);
    });
  };

  getUserParkPlaceReservation = async () => {
    log('[api-context] getAuthorizationHeader', this.props.loginContext);
    await axios.get(`${process.env.REACT_APP_API_URL}/parking-reservations/user`, this.getAuthorizationHeader()).then(async (res) => {
      log('[api-context] load user parking reservation', res);
      const parkingReservation = res.data;

      this.setState({ parkingReservation });
    }).catch((e) => {
      console.error(e);
    });
  };

  getUserParkPlacePreReservation = async () => {
    log('[api-context] getAuthorizationHeader', this.props.loginContext);
    await axios.get(`${process.env.REACT_APP_API_URL}/parking-pre-reservations/user`, this.getAuthorizationHeader()).then(async (res) => {
      log('[api-context] load user parking pre-reservation', res);
      const parkingPreReservation = res.data;
      this.setState({ parkingPreReservation });
    }).catch((e) => {
      console.error(e);
    });
  };

  reserveDesk = (deskId) => {
    const { isLogged } = this.props.loginContext;

    if (isLogged) {
      const reservation = {
        deskId,
      };
      return axios.post(`${process.env.REACT_APP_API_URL}/reservations/reserve`, reservation, this.getAuthorizationHeader()).then(async (res) => {
        log('[api-context] create reservation', res);
        const { data } = res;
        const desk = this.state.desks.filter((d) => d.id === data.desk.id);
        const reservationRes = {
          id: data.id,
          desk: desk[0],
        };
        this.setState({ reservation: reservationRes });
      });
    }

    return null;
  };

  reserveParkPlace = (parkPlaceId) => {
    const { isLogged } = this.props.loginContext;

    if (isLogged) {
      const reservation = {
        parkPlaceId,
      };
      return axios.post(`${process.env.REACT_APP_API_URL}/parking-reservations/reserve`, reservation, this.getAuthorizationHeader()).then(async (res) => {
        log('[api-context] create parking reservation', res);
        const { data } = res;
        const parkPlace = this.state.parkPlaces.filter((pp) => pp.id === data.parkPlace.id);
        const reservationRes = {
          id: data.id,
          parkPlace: parkPlace[0],
        };
        this.setState({ parkingReservation: reservationRes });
      });
    }

    return null;
  };

  cancelReservation = async () => {
    const { reservation } = this.state;
    if (reservation) {
      this.setLoading(true);
      await axios.delete(`${process.env.REACT_APP_API_URL}/reservations/cancel`, this.getAuthorizationHeader()).then(async (res) => {
        log('[api-context] reservation deleted', res);
        this.setState({ reservation: null });
        this.refreshDesks();
      }).catch((e) => {
        console.error(e);
      });
      this.setLoading(false);
    }
  };

  cancelPreReservation = async () => {
    const { preReservation } = this.state;
    if (preReservation) {
      this.setLoading(true);
      await axios.delete(`${process.env.REACT_APP_API_URL}/pre-reservations/cancel`, this.getAuthorizationHeader()).then(async (res) => {
        log('[api-context] pre-reservation deleted', res);
        this.setState({ preReservation: null });
        this.refreshDesks();
      }).catch((e) => {
        console.error(e);
      });
      this.setLoading(false);
    }
  };

  cancelParkingReservation = async () => {
    const { parkingReservation } = this.state;
    if (parkingReservation) {
      this.setLoading(true);
      await axios.delete(`${process.env.REACT_APP_API_URL}/parking-reservations/cancel`, this.getAuthorizationHeader()).then(async (res) => {
        log('[api-context] parking reservation deleted', res);
        this.setState({ parkingReservation: null });
        this.refreshParkPlaces();
      }).catch((e) => {
        console.error(e);
      });
      this.setLoading(false);
    }
  };

  cancelParkingPreReservation = async () => {
    const { parkingPreReservation } = this.state;
    if (parkingPreReservation) {
      this.setLoading(true);
      await axios.delete(`${process.env.REACT_APP_API_URL}/parking-pre-reservations/cancel`, this.getAuthorizationHeader()).then(async (res) => {
        log('[api-context] parking-pre-reservation deleted', res);
        this.setState({ parkingPreReservation: null });
        this.refreshParkPlaces();
      }).catch((e) => {
        console.error(e);
      });
      this.setLoading(false);
    }
  };

  reloadDesk = async () => {
    if (this.isActiveFilters()) {
      this.filter();
    } else {
      const desks = await this.getDesks();
      this.setState({ desks });
      this.getFilters(desks);
    }
  };

  reloadParkPlaces = async () => {
    const parkPlaces = await this.getParkPlaces();
    this.setState({ parkPlaces });
  };

  setLoading = (bool) => {
    this.setState({ loading: bool });
  };

  setAutorefreshNotif = (bool) => {
    this.setState({ autorefresh: bool });
  };

  preReserveDesk = (deskId) => {
    const { isLogged } = this.props.loginContext;

    if (isLogged) {
      const preReservationData = {
        deskId,
      };
      return axios.post(`${process.env.REACT_APP_API_URL}/pre-reservations/prereserve`, preReservationData, this.getAuthorizationHeader()).then(async (res) => {
        log('[api-context] create pre-reservation', res);
        const { data } = res;
        const { id, desk } = data;
        const preReservation = {
          id,
          desk,
        };
        this.setState({ preReservation });
      });
    }

    return null;
  };

  preReserveParkPlace = (parkPlaceId) => {
    const { isLogged } = this.props.loginContext;
    if (isLogged) {
      const parkingPreReservationData = {
        parkPlaceId,
      };
      return axios.post(`${process.env.REACT_APP_API_URL}/parking-pre-reservations/prereserve`, parkingPreReservationData, this.getAuthorizationHeader()).then(async (res) => {
        log('[api-context] create parking pre-reservation', res);
        const { data } = res;
        const { id, parkPlace } = data;
        const parkingPreReservation = {
          id,
          parkPlace,
        };
        this.setState({ parkingPreReservation });
      });
    }

    return null;
  };

  async refreshDesks() {
    const desks = await this.getDesks();
    this.setState({ desks });
    this.getFilters(desks);
    log('[api-context] refresh desks');
  }

  async refreshParkPlaces() {
    const parkPlaces = await this.getParkPlaces();
    this.setState({ parkPlaces });
    log('[api-context] refresh park places');
  }

  async filter() {
    const { activeFilters } = this.state;
    const onlyAnyone = activeFilters.only.includes('anyone');
    const desks = await this.getDesks();
    const desksAfterFilter = [];

    const isIncludesId = (id) => {
      let res = false;
      desksAfterFilter.forEach((desk) => {
        if (desk.id === id) res = true;
      });

      return res;
    };

    if (!this.isActiveFilters()) {
      log('[api-context] no active filters');
      this.setState({ desks });
    } else {
      if (onlyAnyone) {
        activeFilters.groups = [];
      }

      desks.forEach((desk) => {
        let floorInclude = false;
        let groupInclude = false;
        let equipmentsInclude = false;
        let onlyInclude = false;

        activeFilters.floors.forEach((floor) => {
          if (desk.place.floor === floor && !isIncludesId(desk.id)) {
            floorInclude = true;
          }
        });

        activeFilters.groups.forEach((groupId) => {
          desk.groups.forEach((group) => {
            if (group.id === groupId) {
              groupInclude = true;
            }
          });
        });

        activeFilters.equipments.forEach((equipmentId) => {
          desk.equipments.forEach((equip) => {
            if (equip.id === equipmentId) {
              equipmentsInclude = true;
            }
          });
        });

        if (activeFilters.groups.length > 0 && desk.mode === 'anyone') {
          groupInclude = false;
        }

        if (onlyAnyone && desk.mode === 'anyone') {
          onlyInclude = true;
        }

        if (activeFilters.floors.length === 0) {
          floorInclude = true;
        }

        if (activeFilters.equipments.length === 0) {
          equipmentsInclude = true;
        }

        if (activeFilters.groups.length === 0) {
          groupInclude = true;
        }

        if (activeFilters.only.length === 0) {
          onlyInclude = true;
        }

        if (floorInclude && groupInclude && onlyInclude && equipmentsInclude) {
          desksAfterFilter.push(desk);
        }

        log(`[api-context] filter desks floorInclude: ${floorInclude} equipmentsInclude: ${equipmentsInclude} groupInclude: ${groupInclude} onlyInclude: ${onlyInclude}`);
      });

      log('[api-context] filter desks', desks, activeFilters, desksAfterFilter);

      /*
      activeFilters.groups.forEach((groupId) => {
        desksAfterFilter = desks.filter((desk) => {
          let hasGroup = false;
          desk.equipments.forEach((group) => {
            if (group.id === groupId) {
              hasGroup = true;
            }
          });
          return hasGroup;
        });
      });
      */

      // log(`[api-context] filter desks`, desksAfterFilter);
      this.setState({ desks: desksAfterFilter });
    }
  }

  render() {
    return (
      <Provider
        value={{
          reservation: this.state.reservation,
          preReservation: this.state.preReservation,
          parkingReservation: this.state.parkingReservation,
          parkingPreReservation: this.state.parkingPreReservation,
          direct: this.state.direct,
          desks: this.state.desks,
          parkPlaces: this.state.parkPlaces,
          loading: this.state.loading,
          autorefresh: this.state.autorefresh,
          availableFilters: this.state.availableFilters,
          activeFilters: this.state.activeFilters,
          filterChange: this.filterChange,
          resetFilters: this.resetFilters,
          getDesks: this.getDesks,
          getParkPlaces: this.getParkPlaces,
          reserveDesk: this.reserveDesk,
          reserveParkPlace: this.reserveParkPlace,
          cancelReservation: this.cancelReservation,
          cancelPreReservation: this.cancelPreReservation,
          cancelParkingReservation: this.cancelParkingReservation,
          cancelParkingPreReservation: this.cancelParkingPreReservation,
          isActiveFilters: this.isActiveFilters,
          reloadDesk: this.reloadDesk,
          reloadParkPlaces: this.reloadParkPlaces,
          removeDirectLink: this.removeDirectLink,
          preReserveDesk: this.preReserveDesk,
          preReserveParkPlace: this.preReserveParkPlace,
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

const withApiContext = (InputComponent) => (
  function (props) {
    return (
      <Consumer>
        {(context) => <InputComponent apiContext={context} {...props} />}
      </Consumer>
    );
  }
);

export default withRouter(withLoginContext(ApiContextProvider));

export { ApiContextProvider, Consumer as ApiContextConsumer, withApiContext };
