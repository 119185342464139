import React from 'react';
import {
  Button, Box, Image, Text,
} from '@chakra-ui/react';
import { withApiContext } from '../../Context/ApiContext';
import propertyTester from '../../Utils/PropertyTester';

function PreRevisionItem(props) {
  const noPhotoUrl = 'https://res.cloudinary.com/hz1vl72op/image/upload/v1632089452/static/no_photo_new_mxiisc.png';
  const { preReservation, cancelPreReservation } = props.apiContext;
  const { name, place, photo } = preReservation.desk;
  const { floor, shortTitle } = place;
  const photoUrl = propertyTester(() => photo.formats.medium.url) || noPhotoUrl;

  return (
    <Box mb="50px" display="flex" flexDir="column" alignItems={['center', 'center', 'center', 'flex-start']}>
      <Text
        fontSize="3xl"
        mb="5"
        borderBottom="1px solid black"
        textAlign={['center', 'left', 'left', 'left']}
        width="100%"
      >
        Tvoje zítřejší rezervace
      </Text>
      <Box
        maxW={['261px', '420px', '420px', '420px']}
        h={['auto', '150px', '150px', '150px']}
        minW="261px"
        pos="relative"
        borderRadius="md"
        overflow="hidden"
        boxShadow="md"
        bg="white"
        cursor="pointer"
        transition="transform 300ms"
        display="flex"
        flexDir={['column', 'row', 'row', 'row']}
        _hover={{
          boxShadow: 'lg',
          transform: 'scale(1.05)',
        }}
      >
        <Image src={`${photoUrl}`} alt={`Stůl ${name}`} />
        <Box p="4" display="flex" flexDir="column" justifyContent="space-between" w="100%">
          <Box>
            <Text
              fontWeight="semibold"
              lineHeight="tight"
              isTruncated
              fontSize="1.2rem"
              textTransform="uppercase"
            >{name}
            </Text>
            <Text textTransform="uppercase">
              {(floor === 0 ? 'Přízemí' : `${floor}. patro`)} - {shortTitle}
            </Text>
          </Box>
          <Box
            display="flex"
            justifyContent={['center', 'center', 'center', 'flex-end']}
            mt="4"
          >
            <Button
              colorScheme="red"
              size="md"
              onClick={() => {
                cancelPreReservation();
              }}
            >ZRUŠIT REZERVACI
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default withApiContext(PreRevisionItem);
