import React from 'react';
import { Button, ButtonGroup, HStack } from '@chakra-ui/react';
import { useLocation, useHistory } from 'react-router-dom';
import { HOMEPAGE, PARKING } from '../routes';

export function TypeMenu() {
  const location = useLocation();
  const history = useHistory();
  return (
    <HStack mb="10">
      <ButtonGroup isAttached boxShadow="md">
        <Button
          isActive={(location.pathname === HOMEPAGE)}
          onClick={() => history.push(HOMEPAGE)}
        >Sdílené stoly
        </Button>
        <Button
          isActive={(location.pathname === PARKING)}
          onClick={() => history.push(PARKING)}
        >Parkovací místa
        </Button>
      </ButtonGroup>
    </HStack>
  );
}

export default TypeMenu;
