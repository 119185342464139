/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-named-as-default */
import React, { Component } from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';
import {
  ChakraProvider,
  Flex,
  Container,
} from '@chakra-ui/react';
import LoginScreen from './Screens/Login';
import MainScreen from './Screens/MainPage';
import ParkingScreen from './Screens/Parking';
import { withLoginContext } from './Context/LoginContext';
import { withApiContext } from './Context/ApiContext';
import Footer from './Components/Footer';
import Header from './Components/Header';
import {
  HOMEPAGE, LOGIN, HOW_WORKS, REGISTER, FORGOT_PASSWORD,
  PARKING,
} from './routes';
import PrivateRoute from './Components/PrivateRoute';
import Intro from './Screens/Intro';
import theme from './Theme/theme';
import Fonts from './Theme/fonts';
import { Loader } from './Components/Loader';
import { HowWorksScreen } from './Screens/HowWorks';
import Register from './Screens/Register';
import ForgotPassword from './Screens/ForgotPassword';

class App extends Component {
  constructor() {
    super();

    this.state = {
      introDone: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ introDone: true });
    }, 2500);
  }

  /**
 * dark green #073E2E
 * green #60986F
 * light green #91AE8D
 * orange #FADF7A
 * yellow #F9C403
 */

  render() {
    const { introDone } = this.state;
    const { loading } = this.props.apiContext;

    if (!introDone && process.env.REACT_APP_LOADER !== 'false') {
      return (
        <ChakraProvider theme={theme}>
          <Fonts />
          <Intro />
        </ChakraProvider>
      );
    }

    return (
      <ChakraProvider theme={theme}>
        <Fonts />
        {loading && <Loader />}
        <Flex
          minH="100vh"
          p={3}
          justify="space-between"
          align="center"
          direction="column"
        >
          <Container maxWidth="1200px" pb="10">
            <Header />
          </Container>
          <Container maxWidth="1200px">
            <Switch>
              <Route path={LOGIN}>
                <LoginScreen />
              </Route>
              <Route path={HOW_WORKS}>
                <HowWorksScreen />
              </Route>
              <Route path={REGISTER}>
                <Register />
              </Route>
              <Route path={FORGOT_PASSWORD}>
                <ForgotPassword />
              </Route>
              <PrivateRoute path={PARKING}>
                <ParkingScreen />
              </PrivateRoute>
              <PrivateRoute path={HOMEPAGE}>
                <MainScreen />
              </PrivateRoute>
            </Switch>
          </Container>
          <Container maxWidth="1200px">
            <Footer />
          </Container>
        </Flex>
      </ChakraProvider>
    );
  }
}

export default withLoginContext(withApiContext(App));

/*
<ChakraProvider theme={theme}>
      <Router>

      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          <ColorModeSwitcher justifySelf="flex-end" />
          <VStack spacing={8}>
            <Logo h="40vmin" pointerEvents="none" />
            <Text>
              Edit <Code fontSize="xl">src/App.js</Code> and save to reload.
            </Text>
            <Link
              color="teal.500"
              href="https://chakra-ui.com"
              fontSize="2xl"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn Chakra
            </Link>
          </VStack>
        </Grid>
      </Box>
    </ChakraProvider> */
