import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Center,
  Box,
  Text,
} from '@chakra-ui/react';
import { withApiContext } from '../../Context/ApiContext';

function tomorrowText() {
  const days = ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota'];

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  return `Zítra bude ${days[tomorrow.getDay()].toLowerCase()} ${tomorrow.getDate()}. ${tomorrow.getMonth() + 1}. ${tomorrow.getFullYear()}`;
}
function PreReservationModal(props) {
  if (!props.desk) return null;
  const { preReserveDesk } = props.apiContext;
  const { isOpen, onClose, openDialog } = props;
  const { desk } = props;
  const { floor, shortTitle } = desk.place;

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size="xl" m="10px">
      <ModalOverlay />
      <ModalContent m="5">
        <ModalHeader textTransform="uppercase">
          Předrezervace
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Center>
            <VStack>
              <Box>
                <Text
                  align="center"
                  fontWeight="semibold"
                  lineHeight="tight"
                  isTruncated
                  fontSize="1.8rem"
                  textTransform="uppercase"
                >
                  {desk.name}
                </Text>
                <Text align="center" textTransform="uppercase" mb="5">
                  {(floor === 0 ? 'Přízemí' : `${floor}. patro`)} - {shortTitle}
                </Text>
                <Button
                  size="lg"
                  colorScheme="deskeeGreen"
                  onClick={async () => {
                    await preReserveDesk(desk.id).then(() => {
                      onClose();
                      openDialog(
                        {
                          type: 'success',
                          message: 'Zítřejší rezervace aktivní',
                        },
                      );
                    }).catch((e) => {
                      onClose();
                      openDialog(
                        {
                          type: 'error',
                          message: e.response.data.message,
                        },
                      );
                    });
                  }}
                >Rezervovat na zítra
                </Button>
                <Text mt="8" align="center" fontStyle="italic">
                  {tomorrowText()}
                </Text>
              </Box>
            </VStack>
          </Center>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
}

export default withApiContext(PreReservationModal);
