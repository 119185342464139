import React, { Component } from "react";
import axios from 'axios';
import { LOGIN } from "../routes";
import { Redirect } from 'react-router-dom';
import log from '../Utils/Logger';
import propertyTester from '../Utils/PropertyTester';
const { Provider, Consumer } = React.createContext();

class LoginContextProvider extends Component {
  state = {
    jwt: null,
    username: null,
    userId: null,
    userGroups: [],
    loaded: false,
    loginError: false,
  };

  isLogged = () => {
    if (this.state.jwt !== null) {
      return true;
    } 

    return false;
  };

  loginProtect = () => {
    if (!this.isLogged()) {
      const { location } = this.props;

      return (
        <Redirect
          to={{
            pathname: LOGIN,
            state: { from: location }
          }}
        />
      ) 
    }
  }

  getAuthorizationHeader = (jwt) => {
    log(`[login-context] getAuthorizationHeader`, jwt);
    return { headers: {
        Authorization: `Bearer ${jwt}`,
      }
    }
  };

  async componentDidMount() {
    const persistStorage = JSON.parse(window.localStorage.getItem('deskee')) || {}
    const { jwt } = persistStorage;

    if (jwt) {
      await axios.get(`${process.env.REACT_APP_API_URL}/users/me`, this.getAuthorizationHeader(jwt)).then((res) => {
        log(`[login-context] user/me success`, jwt, res);
        const { data } = res;
        this.setState({
          jwt,
          username: data.username,
          userId: data.id,
          userGroups: data.groups || [],
          loaded: true,
          loginError: false,
        });
      }).catch((e) => {
        log(`[login-context] user/me corrupt remove jwt`, e);
        window.localStorage.removeItem('deskee');
        this.setState({
          loaded: true,
          loginError: false,
        });
      });
    } else {
      log(`[login-context] token not found`);
      this.setState({
        loaded: true,
        loginError: false,
      });
    }

    // await this.login(store.identifier, store.password);
  }

  login = (email, password, permanent) => {
    const identifier = email.trim();
    return axios.post(`${process.env.REACT_APP_API_URL}/auth/local`, {
      identifier,
      password,
    }).then((res) => {
      const { data } = res;
      this.setState({
        jwt: data.jwt,
        username: data.user.username,
        userId: data.user.id,
        userGroups: data.user.groups,
        loginError: false,
      });
      if (permanent) {
        window.localStorage.setItem('deskee', JSON.stringify({
          jwt: data.jwt,
        }));
      }
      return true;
    }).catch((error) => {
      log(`[login-context] error`, error);
      this.setState({
        jwt: null,
        username: null,
        userId: null,
        userGroups: null,
        loginError: true,
      });
      return false;
    });
  }

  logout = () => {
    window.localStorage.removeItem('deskee');
    this.setState({ jwt: null, username: null });
  }

  getErrorMessage = (error) => {
    const errorId = propertyTester(() => error.response.data.message[0].messages[0].id);
    const message = propertyTester(() => error.response.data.message[0].messages[0].message, 'Neznámá chyba registrace');
    const errors = {
      'Auth.form.error.email.taken': 'Email je již zaregistrován',
      'Auth.form.error.email.provide': 'Zadejte email',
      'Auth.form.error.email.format': 'Zadaný email není validní',
      'Auth.form.error.user.not-exist': 'Zadaný email neexistuje',
      'Auth.form.error.code.provide': 'Neplatný resetovací kód'
    };

    return errors[errorId] || message;
  }

  register = (username, email, password) => {
      return axios.post(`${process.env.REACT_APP_API_URL}/auth/local/register`, {
        username,
        email,
        password,
      }).then((res) => {
        const { data } = res;
        log(`[login-context] register `, data);
        return { status: 'done' };
      }).catch((error) => {
        log(`[login-context] register error`, error.response);
        return { status: 'error', message: this.getErrorMessage(error) };
      });
  }

  forgotPassword = (email) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, {
      email,
    })
    .then(response => {
      log(`[login-context] forgot password - send email to ${email}`);
      return { status: 'done' };
    })
    .catch(error => {
      log(`[login-context] forgot password error`, error.response);
      return { status: 'error', message: this.getErrorMessage(error) };
    });
  }

  resetPassword = (code, password, passwordConfirmation) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/auth/reset-password`, {
      code,
      password,
      passwordConfirmation,
    })
    .then(response => {
      log(`[login-context] reset password done!`);
      return { status: 'done' };
    })
    .catch(error => {
      log(`[login-context] reset password error`, error.response);
      return { status: 'error', message: this.getErrorMessage(error) };
    });
  }

  render() {
    const { jwt, username, userId, userGroups, loaded, loginError } = this.state;
    return (
      <Provider
        value={{ 
          jwt,
          username,
          userId,
          userGroups,
          loaded,
          loginError,
          isLogged: this.isLogged, 
          login: this.login, 
          logout: this.logout,
          register: this.register,
          forgotPassword: this.forgotPassword,
          resetPassword: this.resetPassword,
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

const withLoginContext = InputComponent => (
  props => (
    <Consumer>
      {context => <InputComponent loginContext={context} {...props} />}
    </Consumer>
  )
);

export { LoginContextProvider, Consumer as LoginContextConsumer, withLoginContext };