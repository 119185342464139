import React, { Component } from 'react';
import { Center, Stack, InputGroup, Text, Input, InputLeftElement, Button, Checkbox, HStack } from '@chakra-ui/react';
import { withLoginContext } from '../Context/LoginContext';
import { FiUser, FiUnlock, FiEye, FiEyeOff } from "react-icons/fi";
import { withRouter, Redirect, Link } from 'react-router-dom';
import { HOMEPAGE, REGISTER, FORGOT_PASSWORD } from '../routes';
class Login extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      user: '',
      pass: '',
      permanent: false,
      showPass: false,
    }

    this.login = this.login.bind(this);
  }

  async login() {
    const { user, pass, permanent } = this.state;
    const { loginContext } = this.props;
    await loginContext.login(user, pass, permanent);

    /**
     * .then(() => {
      let { from } = location.state || { from: { pathname: "/" } };
      history.replace(from);
    });
     * {
        "identifier": "jakub.frydrych",
        "password": "alfaalfa"
      }
     */
  }
  
  handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({
      [name]: value
    });
  }

  togglePassShow = () => {
    const { showPass } = this.state;
    this.setState({showPass: !showPass});
  }

  render() {
    const { user, pass, permanent, showPass } = this.state;
    const { location } = this.props;
    const { isLogged, loginError } = this.props.loginContext;

    if (isLogged()) {
      return <Redirect
        to={{
          pathname: HOMEPAGE,
          state: { from: location }
        }}
      />;
    }

    return (
      <form>
      <Center>
        
        <Stack spacing={4}>
        {loginError && 
          <Text 
            align="center" 
            color="red"
            textTransform="uppercase"
            fontWeight="600"
          >
              Chyba přihlášení
          </Text>
        }
          <InputGroup size="lg">
            <InputLeftElement
              pointerEvents="none"
              children={<FiUser color="gray.300" />}
            />
            <Input 
              variant="filled" 
              name="user"
              type="text"
              placeholder="your@email"
              onChange={this.handleChange}
              value={user}
              autoComplete="username"
            />
          </InputGroup>
          <InputGroup size="lg">
            {pass === '' && <InputLeftElement
              children={<FiUnlock color="gray.300" />}
            />}
            {pass !== '' && <InputLeftElement 
              children={(!showPass ? <FiEye color="gray.300" /> : <FiEyeOff color="gray.300" />)}
              onClick={this.togglePassShow}
            />}
            <Input 
              variant="filled"
              type={showPass ? 'text' : 'password'}
              name="pass"
              value={pass}
              onChange={this.handleChange}
              autoComplete="current-password"
            />
          </InputGroup>
          <Center>
            <Checkbox 
              colorScheme="deskeeGreen"
              name="permanent"
              onChange={this.handleChange}
              isChecked={permanent}
            >
              Trvalé přihlášení
            </Checkbox>
          </Center>
          <Button 
            textTransform="uppercase" 
            colorScheme="deskeeGreen" 
            onClick={this.login}>
              Přihlásit se
          </Button>

          <Link to={FORGOT_PASSWORD}>
            <Text textAlign="center" textDecor="underline">
              Zapomenuté heslo?
            </Text>
          </Link>
          <HStack justifyContent="center">
              <Text color="black">
                Nemáš účet?
              </Text>
              <Link to={REGISTER}>
                <Text textDecor="underline">
                  Vytvořit nový
                </Text>
              </Link>
            </HStack>
        </Stack>
      </Center>
      </form>
    );
  }
}

export default withRouter(withLoginContext(Login));