/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import {
  Text, Link, Image, Center, VStack, Box,
} from '@chakra-ui/react';
import illustration from '../Images/illustration.png';

class Footer extends Component {
  render() {
    return (
      <Center
        mt="4rem"
        textAlign="center"
        color="#000"
        fontSize="14px"
      >
        <VStack>
          <Image src={illustration} boxSize="220px" />
          <Box>
            <Text fontWeight="semibold" display="inline">Created Frydrych&sons 2021</Text> - <Link href="mailto:jakub.frydrych@gmail.com" textDecor="underline">Contact Us</Link> (v{process.env.REACT_APP_VERSION})
          </Box>
        </VStack>
      </Center>
    );
  }
}

export default Footer;
