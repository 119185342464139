/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  Image, Flex, Spacer, ButtonGroup, IconButton, Button, Center, Box, Text, Icon,
} from '@chakra-ui/react';
import {
  FiLogOut, FiHelpCircle, FiHome, FiRefreshCcw,
} from 'react-icons/fi';
import logoImg from '../Images/logo.svg';
import {
  HOMEPAGE, HOW_WORKS, LOGIN, REGISTER, PARKING,
} from '../routes';
import { withLoginContext } from '../Context/LoginContext';
import { withApiContext } from '../Context/ApiContext';

class Header extends Component {
  render() {
    const { pathname } = this.props.location;
    const isLoginPage = (pathname === LOGIN);
    const isHowWorskPage = (pathname === HOW_WORKS);
    const isMainPage = (pathname === HOMEPAGE);
    const isRegisterPage = (pathname === REGISTER);
    const isParkingPage = (pathname === PARKING);
    const { logout, isLogged, username } = this.props.loginContext;
    const { autorefresh } = this.props.apiContext;

    return (
      <Flex
        justify={(isLoginPage ? 'center' : 'flex-start')}
        align={{ base: 'center', sm: 'base', md: 'flex-start' }}
        flexDir={['column', 'column', 'row']}
        pt="2rem"
      >
        <Link to={HOMEPAGE}>
          <Image
            boxSize={(isLoginPage ? '150px' : { base: '150px', md: '110px' })}
            align="center"
            objectFit="contains"
            src={logoImg}
            alt="Deskee - office desk reservation system"
          />
        </Link>
        {isLogged() && <Spacer />}
        {isLogged()
        && (
        <>
          {autorefresh && (
          <Box h="100%" mr="5" mt="1">
            <Center>
              <Icon as={FiRefreshCcw} mr="2" />
              <Text>autorefresh</Text>
            </Center>
          </Box>
          )}
          <ButtonGroup
            size="sm"
            isAttached
            variant="solid"
            mt={{ base: '2rem', md: '0' }}
          >
            <Button
              mr="-px"
              textTransform="uppercase"
              boxShadow="sm"
              pointerEvents="none"
            >
              {username}
            </Button>
            {(isMainPage || isParkingPage) && (
            <IconButton
              aria-label="How DESKEE works"
              icon={<FiHelpCircle />}
              borderLeft="1px solid silver"
              onClick={() => {
                this.props.history.push(HOW_WORKS);
              }}
              boxShadow="sm"
            />
            )}
            {isHowWorskPage && (
            <IconButton
              aria-label="DESKEE main page"
              icon={<FiHome />}
              borderLeft="1px solid silver"
              onClick={() => {
                this.props.history.push(HOMEPAGE);
              }}
              boxShadow="sm"
            />
            )}
            <IconButton
              aria-label="Logout"
              icon={<FiLogOut />}
              borderLeft="1px solid silver"
              onClick={logout}
              boxShadow="sm"
            />
          </ButtonGroup>
        </>
        )}
        {(isLoginPage || isRegisterPage)
          && (
          <IconButton
            aria-label="How DESKEE works"
            icon={<FiHelpCircle />}
            pos="absolute"
            right="15px"
            top="15px"
            // colorScheme="deskeeGreen"
            size="md"
            shadow="md"
            onClick={() => {
              this.props.history.push(HOW_WORKS);
            }}
          />
          )}
        {(!isLogged() && isHowWorskPage)
          && (
          <IconButton
            aria-label="Home DESKEE"
            icon={<FiHome />}
            pos="absolute"
            right="15px"
            top="15px"
            // colorScheme="deskeeGreen"
            size="md"
            shadow="md"
            onClick={() => {
              this.props.history.push(HOMEPAGE);
            }}
          />
          )}
      </Flex>
    );
  }
}

export default withRouter(withLoginContext(withApiContext(Header)));
