/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Image, Box, Spacer, Flex,
  Button, IconButton, VStack,
  Badge,
} from '@chakra-ui/react';
import { BiMoneyWithdraw } from 'react-icons/bi';
import { withApiContext } from '../../Context/ApiContext';
import propertyTester from '../../Utils/PropertyTester';

function getModeTriangle(mode) {
  const triangleStyle = {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 25px 25px 0',
    borderColor: `transparent ${(mode === 'anyone' ? '#1EC655' : '#f2eb21')} transparent transparent`,
    position: 'absolute',
    right: 0,
    top: 0,
  };

  return <div style={triangleStyle} title={`místo se nachází v režimu ${mode}`} />;
}

function ListItem(props) {
  const noPhotoUrl = 'https://res.cloudinary.com/hz1vl72op/image/upload/v1632089452/static/no_photo_new_mxiisc.png';
  const {
    name, photo, id, mode,
    parkingPreReservation: placeParkingPreReservation,
    parkingReservation: placeParkingReservation,
    place,
    paid, price,
  } = props.data;
  const {
    reserveParkPlace,
    cancelParkingReservation,
    parkingPreReservation: userParkingPreReservation,
  } = props.apiContext;
  const {
    isReserved, showPayment, openDialog, showPrereservation,
  } = props;
  const photoUrl = propertyTester(() => photo.formats.medium.url) || noPhotoUrl;
  const preReservationEnable = !placeParkingPreReservation && !userParkingPreReservation;
  const reservationEnable = !placeParkingReservation;

  return (
    <Box
      pos="relative"
      maxW="261px"
      minW="200px"
      // height="440px"
      borderRadius="md"
      overflow="hidden"
      boxShadow="md"
      bg="white"
      cursor="pointer"
      transition="transform 300ms"
      _hover={{
        boxShadow: 'lg',
        transform: 'scale(1.05)',
      }}
    >

      <Image src={`${photoUrl}`} alt="" />
      {getModeTriangle(mode)}
      <VStack
        position="absolute"
        left="10px"
        top="10px"
        display="flex"
        alignItems="baseline"
        flexWrap="wrap"
      >
        {paid && price
          && (
          <Badge
            borderRadius="1"
            colorScheme="deskeeYellow"
            fontSize="14px"
          >
            {price} Kč
          </Badge>
          )}
      </VStack>
      <Box p="3">
        <Box
          fontWeight="semibold"
          lineHeight="tight"
          isTruncated
          fontSize="1.2rem"
          textTransform="uppercase"
        >
          {name}
        </Box>

        {!isReserved && (
          <Box mt="4" textAlign="center">
            <VStack spacing="4">
              <Button
                colorScheme="deskeeGreen"
                size="md"
                disabled={!reservationEnable}
                onClick={async () => {
                  await reserveParkPlace(id).catch((e) => {
                    openDialog(
                      {
                        type: 'error',
                        message: e.response.data.message,
                      },
                    );
                  });
                }}
              >
                REZERVOVAT DNES
              </Button>
              <Button
                variant="solid"
                colorScheme={preReservationEnable ? 'purple' : 'gray'}
                size="sm"
                aria-label="Open prereservation"
                onClick={showPrereservation}
                disabled={!preReservationEnable}
              >
                REZERVOVAT NA ZÍTRA
              </Button>
            </VStack>
          </Box>
        )}

        {isReserved && (
          <Box mt="4" textAlign="center">
            <VStack>
              <Button
                colorScheme="red"
                size="lg"
                onClick={() => {
                  cancelParkingReservation();
                }}
              >
                ZRUŠIT REZERVACI
              </Button>
              <Button
                size="sm"
                variant="solid"
                colorScheme={preReservationEnable ? 'purple' : 'gray'}
                aria-label="Open prereservation"
                // icon={<FiClock />}
                onClick={showPrereservation}
                disabled={!preReservationEnable}
              >
                REZERVOVAT NA ZÍTRA
              </Button>
            </VStack>
          </Box>
        )}

        <Flex mt="4" align="center">
          {paid && (
          <Box>
            <IconButton
              icon={<BiMoneyWithdraw />}
              size="sm"
              onClick={showPayment}
            />
          </Box>
          )}
          <Spacer />
          <Box
            p="2"
            textTransform="uppercase"
          >
            {place.shortTitle}
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}

export default withApiContext(ListItem);
