import React from 'react';
import { Center, Image, Text, Box, VStack } from '@chakra-ui/react';
// import logoImg from '../Images/logo_animation.gif';
import signature from '../Images/signature.png';


export const IntroScreen = props => {
  return (
    <Center 
      minH="100vh" 
      p={3} 
    >
      <Box display="block" textAlign="center">
        <Image 
            boxSize={{base: '250px', md:"300px"}}
            align="center"
            objectFit="contain"
            src="https://res.cloudinary.com/hz1vl72op/image/upload/c_scale,w_761/v1626044529/Deskee_animace_ssbpzh.gif"
            alt="Deskee - office desk reservation system" 
            //animation={animation}
        />
        <Text fontWeight="bold" fontSize="4rem">DESKEE</Text>
        <Text fontWeight="600">
            by Frydrych&sons
          </Text>
        <Center mt={{base: '2rem', md: '5rem'}}>
          <VStack>
            <Text mt="2rem" fontSize={{base: '0.8rem', md: '1rem'}}>
              Illustrations by
            </Text>
            <Image 
              width={{base: '120px', md:"150px"}}
              height="70px"
              bgPosition="center"
              objectFit="contain"
              src={signature}
              alt="Deskee - office desk reservation system" 
              //animation={animation}
            />
          </VStack>
        </Center>        
      </Box>  
      
    </Center>
  );
}

export default IntroScreen;
