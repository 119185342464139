import {
  Global
} from "@emotion/react"

const Fonts = () => ( <
  Global styles = {
    `
      @font-face {
    font-family: 'akrobat';
    src: url('./fonts/akrobat-black-webfont.woff2') format('woff2'),
         url('./fonts/akrobat-black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}




@font-face {
    font-family: 'akrobat';
    src: url('./fonts/Akrobat-Bold.woff2') format('woff2'),
         url('./fonts/Akrobat-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}




@font-face {
    font-family: 'akrobat';
    src: url('./fonts/Akrobat-ExtraBold.woff2') format('woff2'),
         url('./fonts/Akrobat-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;

}




@font-face {
    font-family: 'akrobat';
    src: url('./fonts/Akrobat-ExtraLight.woff2') format('woff2'),
         url('./fonts/Akrobat-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;

}




@font-face {
    font-family: 'akrobat';
    src: url('./fonts/Akrobat-Light.woff2') format('woff2'),
         url('./fonts/Akrobat-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;

}




@font-face {
    font-family: 'akrobat';
    src: url('./fonts/Akrobat-Regular.woff2') format('woff2'),
         url('./fonts/Akrobat-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}




@font-face {
    font-family: 'akrobat';
    src: url('./fonts/Akrobat-SemiBold.woff2') format('woff2'),
         url('./fonts/Akrobat-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;

}


@font-face {
    font-family: 'akrobat';
    src: url('./fonts/Akrobat-Thin.woff2') format('woff2'),
         url('./fonts/Akrobat-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}
      `
  }
  />
)

export default Fonts