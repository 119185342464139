import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
  Text,
  VStack,
  Center,
  Flex,
  Spacer,
  ButtonGroup,
  IconButton,
} from '@chakra-ui/react';
import { FiMail, FiMessageSquare } from 'react-icons/fi';

function InfoModal(props) {
  if (!props.desk) return null;
  const { isOpen, onClose } = props;
  const { desk } = props;

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size="xl">
      <ModalOverlay />
      <ModalContent m="5">
        <ModalHeader textTransform="uppercase">
          {desk.name}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Center>
            <VStack>
              <Text>
                Mapa umístění stolu
                {' '}
                {(desk.place.floor === 0 ? 'Přízemí' : `${desk.place.floor}. patro`)}
              </Text>
              <Image
                src={`${desk.place.mapImage.url}`}
                alt=""
                objectFit="contain"
              />
              <Text>
                Správce místa
                {' '}
                {desk.place.managerName}
              </Text>
            </VStack>
          </Center>
        </ModalBody>
        <ModalFooter>
          <Flex w="100%">
            <ButtonGroup size="md" isAttached variant="solid" colorScheme="deskeeGreen">
              <IconButton
                mr="-px"
                aria-label="Contact place manager via email"
                icon={<FiMail />}
                onClick={() => {
                  window.open(`mailto:${desk.place.managerEmail}?subject=[DESKEE]`, '_blank');
                }}
              />
              <IconButton
                aria-label="Contact place manager via chat"
                icon={<FiMessageSquare />}
                borderLeft="1px solid"
                borderColor="deskeeGreen.600"
                onClick={() => {
                  window.open(desk.place.managerChat, '_blank');
                }}
              />
            </ButtonGroup>
            <Spacer />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default InfoModal;
