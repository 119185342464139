/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import {
  Wrap, WrapItem, Text, Icon, Center, useDisclosure, VStack,
  Container, IconButton, Box,
} from '@chakra-ui/react';
import {
  FiEyeOff,

  FiRefreshCw,
} from 'react-icons/fi';
import { withLoginContext } from '../Context/LoginContext';
import ListItem from '../Components/ParkPlace/ListItem';
import { withApiContext } from '../Context/ApiContext';
// import InfoModal from '../Components/Desk/InfoModal';
import ErrorModal from '../Components/Dialog';
import PreReservationModal from '../Components/ParkPlace/PreReservationModal';
import PaymentModal from '../Components/ParkPlace/PaymentModal';
import TypeMenu from '../Components/TypeMenu';
import PreReservationItem from '../Components/ParkPlace/PreReservationItem';

function ParkingPage(props) {
  const {
    parkingReservation,
    parkingPreReservation,
    parkPlaces,
    reloadParkPlaces,
  } = props.apiContext;

  const [parkPlacesByPlace, setParkPlacesByPlace] = useState(null);

  const [parkPlaceDetail, setParkPlaceDetail] = useState(null);
  const [dialog, setDialog] = useState(null);

  const { onOpen } = useDisclosure();

  const {
    isOpen: isOpenDialog,
    onOpen: onOpenDialog,
    onClose: onCloseDialog,
  } = useDisclosure();

  const {
    isOpen: isOpenPrereservation,
    onOpen: onOpenPrereservation,
    onClose: onClosePrereservation,
  } = useDisclosure();

  const {
    isOpen: isOpenPayment,
    onOpen: onOpenPayment,
    onClose: onClosePayment,
  } = useDisclosure();

  const openPaymentModal = (parkPlaceId) => {
    const parkPlace = (parkPlaces.find((pp) => pp.id === parkPlaceId));
    setParkPlaceDetail(parkPlace);
    onOpenPayment();
  };

  const openDialog = (dialogData) => {
    setDialog(dialogData);
    onOpenDialog();
  };

  const openParkPlacePrereservation = (parkPlaceId) => {
    const res = parkingReservation || {};
    const parkPlace = res.parkPlace || (parkPlaces.find((pp) => pp.id === parkPlaceId));
    setParkPlaceDetail(parkPlace);
    onOpenPrereservation();
  };

  useEffect(() => {
    if (!parkPlaces) return;

    const prepareParkPlacesByPlace = {};
    parkPlaces.forEach((parkPlace) => {
      if (!prepareParkPlacesByPlace[parkPlace.place.id]) {
        prepareParkPlacesByPlace[parkPlace.place.id] = {
          shortTitle: parkPlace.place.shortTitle,
          parkPlaces: [],
        };
      }

      prepareParkPlacesByPlace[parkPlace.place.id].parkPlaces.push(parkPlace);
    });
    setParkPlacesByPlace(prepareParkPlacesByPlace);
  }, [parkPlaces]);

  if (parkingReservation) {
    const autoDeleteReservationTime = (new Date().getHours() < 18 ? 'v 18:00' : 've 23:59');

    return (
      <Container maxW="100%">
        <ErrorModal
          isOpen={isOpenDialog}
          onClose={onCloseDialog}
          data={dialog}
        />
        <PreReservationModal
          isOpen={isOpenPrereservation}
          onClose={onClosePrereservation}
          parkPlace={parkPlaceDetail}
          openDialog={openDialog}
        />
        <PaymentModal
          isOpen={isOpenPayment}
          onClose={onClosePayment}
          parkPlace={parkPlaceDetail}
        />

        <Box d="flex" justifyContent="center" width="100%" mb="5">
          <TypeMenu />
        </Box>

        {parkingPreReservation && <PreReservationItem data={parkingPreReservation} />}

        <Text
          fontSize={['2xl', '3xl', '3xl', '3xl']}
          borderBottom="1px solid black"
          mb="5"
          textAlign={['center', 'left', 'left', 'left']}
          width="100%"
        >
          Tvoje dnešní parkovací místo
        </Text>
        <Wrap spacing="30px" justify={['center', 'center', 'center', 'flex-start']}>
          <WrapItem key="dsk-reserved">
            <ListItem
              isReserved
              data={parkingReservation.parkPlace}
              showPayment={
                () => {
                  openPaymentModal(parkingReservation.parkPlace.id);
                }
              }
              showPrereservation={
                () => {
                  openParkPlacePrereservation(parkingReservation.parkPlace.id);
                }
              }
            />
          </WrapItem>
        </Wrap>

        <Text
          align="center"
          textTransform="uppercase"
          mt="2rem"
          fontSize="xl"
        >
          Rezervace se automaticky ukončí <strong>{autoDeleteReservationTime}</strong>
        </Text>
      </Container>
    );
  }

  return (
    <Container maxW="100%">
      {/* <InfoModal isOpen={isOpen} onClose={onClose} desk={deskDetail} /> */}
      <PreReservationModal
        isOpen={isOpenPrereservation}
        onClose={onClosePrereservation}
        parkPlace={parkPlaceDetail}
        openDialog={openDialog}
      />
      <ErrorModal
        isOpen={isOpenDialog}
        onClose={onCloseDialog}
        data={dialog}
      />
      <PaymentModal
        isOpen={isOpenPayment}
        onClose={onClosePayment}
        parkPlace={parkPlaceDetail}
      />

      <Box d="flex" justifyContent="center" width="100%" mb="5">
        <TypeMenu />
      </Box>

      {parkingPreReservation && <PreReservationItem data={parkingPreReservation} />}
      {parkingPreReservation && (
      <Text
        fontSize="3xl"
        mb="5"
        borderBottom="1px solid black"
        textAlign={['center', 'left', 'left', 'left']}
        width="100%"
      >
        Aktuálně volná parkovací místa
      </Text>
      )}

      <Box d="flex" justifyContent={['center', 'center', 'center', 'flex-end']} width="100%" mb="5">
        <IconButton
          aria-label="Logout"
          icon={<FiRefreshCw />}
          onClick={reloadParkPlaces}
          boxShadow="md"
          marginRight="15px"
        />
      </Box>

      {parkPlacesByPlace && Object.keys(parkPlacesByPlace).map((placeId, i) => {
        const place = parkPlacesByPlace[placeId];
        return (
          <Box key={`ppp-${i}`} w="100%" mb="10">
            <Text
              key={`plc-${i}`}
              fontWeight="light"
              lineHeight="tight"
              isTruncated
              fontSize="1rem"
              textTransform="uppercase"
              textAlign={['center', 'left', 'left', 'left']}
              mb="5"
            >
              {place.shortTitle}
            </Text>
            <Wrap spacing="30px" justify={['center', 'center', 'center', 'flex-start']}>
              {place.parkPlaces && place.parkPlaces.map((parkPlace, o) => (
                <WrapItem key={`wpp-${o}`}>
                  <ListItem
                    data={parkPlace}
                    onOpen={onOpen}
                    openDialog={openDialog}
                    showPrereservation={() => openParkPlacePrereservation(parkPlace.id)}
                    showPayment={() => openPaymentModal(parkPlace.id)}
                  />
                </WrapItem>
              ))}
            </Wrap>
          </Box>
        );
      })}

      {((!parkPlaces || parkPlaces.length < 1))
          && (
          <Container maxW="100%">
            <Center textAlign="center">
              <VStack spacing={4}>
                <Icon as={FiEyeOff} boxSize="4rem" color="#036474" />
                <Text size="md" color="#036474">Žádná parkovací místa k dispozici</Text>
              </VStack>
            </Center>
          </Container>
          )}
    </Container>
  );
}

export default withLoginContext(withApiContext(ParkingPage));
