function log(...args) {
  const showLog = window.localStorage.getItem('deskee_debug');
  if (showLog) {
    const message = args[0];
    args.shift();
    console.log(message, ...args);
  } else {
    if (!window.deskeeLogs) window.deskeeLogs = [];
    window.deskeeLogs.push({
      timestamp: Date.now(),
      log: args,
    });
  }
}

export default log;
