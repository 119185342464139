import React, { Component } from 'react';
import { 
  Stack,
  Input, 
  Text, 
  Center, 
  Button,
  InputGroup,
  InputLeftElement,
  Heading,
  VStack,
} from '@chakra-ui/react';
import { withLoginContext } from '../Context/LoginContext';
import { FiLock, FiEye, FiEyeOff, FiMail } from "react-icons/fi";
import { withRouter, Redirect, Link } from 'react-router-dom';
import qs from "qs";
import { HOMEPAGE } from '../routes';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      pass: '',
      repass: '',
      showPass: false,
      registerError: false,
      forgotPasswordDone: false,
      forgotPasswordError: false,
      resetPasswordError: false,
      resetPasswordDone: false,
    }
  }

  handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState({
      [name]: value
    });
  }

  togglePassShow = () => {
    const { showPass } = this.state;
    this.setState({showPass: !showPass});
  }

  checkChangeBeforeSend = () => {
    const { email } = this.state;

    if (email === '' || !email.includes('@')) {
      this.setState({ forgotPasswordError: 'Zdá se, že není správně vyplněn email' });
      return false;
    }

    return true;
  }

  checkNewPassBeforeSend = () => {
    const { pass, repass } = this.state;

    if (pass.length < 6) {
      this.setState({ resetPasswordError: 'Heslo musí být dlouhé min. 6 znaků' });
      return false;
    }

    if (pass !== repass) {
      this.setState({ resetPasswordError: 'Heslo a heslo pro ověření se neshodují' });
      return false;
    }

    return true;
  }

  forgotPassword = async () => {
    const { email } = this.state;
    const { loginContext } = this.props;

    if (this.checkChangeBeforeSend()) {
      const request = await loginContext.forgotPassword(email);
      if (request.status === 'done') {
        this.setState({
          forgotPasswordError: false,
          forgotPasswordDone: true,
        });
      } else {
        this.setState({ forgotPasswordError: request.message });
      }
    }
  }

  resetPassword = async () => {
    const { loginContext } = this.props;
    const { pass, repass } = this.state;
    const { location } = this.props;
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    const { code } = params;

    if (this.checkNewPassBeforeSend()) {
      const request = await loginContext.resetPassword(code, pass, repass);
      if (request.status === 'done') {
        this.setState({
          resetPasswordError: false,
          resetPasswordDone: true,
          pass: '',
          repass: '',
        });
      } else {
        this.setState({ resetPasswordError: request.message });
      }
    }

  }

  hasChangeCodeInUrl = () => {
    const { location } = this.props;
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    const { code } = params;

    return code;
  }
  

  render() {
    const { email, pass, repass, showPass, forgotPasswordDone, forgotPasswordError, resetPasswordDone, resetPasswordError } = this.state;
    const { location } = this.props;
    const { isLogged } = this.props.loginContext;
    const codeInUrl = this.hasChangeCodeInUrl();

    if (isLogged()) {
      return <Redirect
        to={{
          pathname: HOMEPAGE,
          state: { from: location }
        }}
      />;
    }

    if (forgotPasswordDone) {
      return (
        <Center>
          <VStack spacing="20px">
          <Heading as="h1" size="4xl" isTruncated>
            Hotovo!
          </Heading>
          <Text fontSize="2xl">Na email <strong>{email}</strong> ti byl odeslán link pro změnu hesla.</Text>
          <Text fontSize="1xl">Prosím nezapomeň zkontrolovat, zda se ti nezatoulal do složky SPAM</Text>
          </VStack>
        </Center>
      );
    }

    if (resetPasswordDone) {
      return (
        <Center>
          <VStack spacing="20px">
          <Heading as="h1" size="4xl" isTruncated>
            Perfektní!
          </Heading>
          <Text fontSize="2xl">Změna hesla se povedla, můžeš se přihlásit.</Text>
          <Link to={HOMEPAGE}>
            <Text textAlign="center" mt="20px" textDecor="underline">
              Zpět na přihlášení
            </Text>
          </Link>
          </VStack>
        </Center>
      );
    }

    if (codeInUrl) {
      return (
        <form>
        <Center>
          <Stack spacing={4}>
            <Heading 
              as="h1" 
              size="md" 
              textAlign="center"
              textTransform="uppercase"
              fontWeight="300"
            >
              Reset hesla
            </Heading>
            <InputGroup size="lg">
              {pass === '' && <InputLeftElement
                children={<FiLock color="gray.300" />}
              />}
              {pass !== '' && <InputLeftElement 
                children={(!showPass ? <FiEye color="gray.300" /> : <FiEyeOff color="gray.300" />)}
                onClick={this.togglePassShow}
              />}
              <Input 
                placeholder="heslo"
                variant="filled"
                type={showPass ? 'text' : 'password'} 
                name="pass"
                value={pass}
                onChange={this.handleChange}
                autoComplete="new-password"
              />
            </InputGroup>
            <InputGroup size="lg">
              <InputLeftElement
                children={<FiLock color="gray.300" />}
              />
              <Input               
                placeholder="heslo znovu"
                variant="filled"
                type={showPass ? 'text' : 'password'} 
                name="repass"
                value={repass}
                onChange={this.handleChange}
                autoComplete="new-password"
              />
            </InputGroup>
            <Button textTransform="uppercase" colorScheme="deskeeGreen" onClick={this.resetPassword}>Změnit heslo</Button>
            {resetPasswordError && <Text align="center" color="red" mt="30px">{resetPasswordError}</Text>}
            <Link to={HOMEPAGE}>
              <Text textAlign="center" mt="20px" textDecor="underline">
                Už jsem si vzpomněl/a
              </Text>
            </Link>
          </Stack>
        </Center>
        </form>
      );
    }

    return (
      <form>
      <Center>
        <Stack spacing={4}>
          <Heading 
            as="h1" 
            size="md" 
            textAlign="center"
            textTransform="uppercase"
            fontWeight="300"
          >
            Zapomenuté heslo
          </Heading>
          <InputGroup size="lg">
            <InputLeftElement
              pointerEvents="none"
              children={<FiMail color="gray.300" />}
            />
            <Input 
              variant="filled" 
              name="email"
              type="email"
              placeholder="email"
              onChange={this.handleChange}
              value={email}
              autoComplete="off"
            />
          </InputGroup>
          <Button textTransform="uppercase" colorScheme="deskeeGreen" onClick={this.forgotPassword}>Reset hesla</Button>
        </Stack>
      </Center>
      {forgotPasswordError && <Text align="center" color="red" mt="30px">{forgotPasswordError}</Text>}
        <Link to={HOMEPAGE}>
          <Text textAlign="center" mt="20px" textDecor="underline">
            Už jsem si vzpomněl/a
          </Text>
        </Link>
      </form>
    );
  }

}

export default withRouter(withLoginContext(ForgotPassword));

/**
 * photo
 * name
 * mode
 * description
 * location
 */