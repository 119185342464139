/* eslint-disable max-len */
import React from 'react';
import {
  Image, Text, VStack, Heading, Link, Button,
} from '@chakra-ui/react';
import { FiRefreshCcw } from 'react-icons/fi';
import log from '../Utils/Logger';

const forceRefreshApp = () => {
  log('force refresh app');
  window.location.reload(true);
};

const deskeePointImgUrl = 'https://res.cloudinary.com/hz1vl72op/image/upload/v1632090213/static/Deskee_stitky_mxia5y.png';
const deskeeIosImgUrl = 'https://res.cloudinary.com/hz1vl72op/image/upload/v1670729453/static/ios_navod_hypsgw.png';

export function HowWorksScreen() {
  return (
    <VStack
      spacing={8}
      align="stretch"
      mt="2rem"
    >
      <Heading
        as="h1"
        size="4xl"
      >
        Jak DESKEE funguje?
      </Heading>
      <Text
        fontSize="xl"
        maxW={{ sm: '90%', md: '70%' }}
        textAlign="justify"
      >
        Pojďme si nejdřív trochu srovnat termity. Co je to vlastně ten sdílený stůl a co je to fixní stůl? <br /><br />
        <strong>Fixní stůl</strong> nás u DESKEE vůbec nezajímá. Majitel fixního stolu chce mít svoje hnízdečko jen pro sebe, a proto ho můžeme z našich sdílecích hrátek vynechat.<br /><br />
        <strong>Sdílený stůl</strong> to už je jiná písnička. Sdíleným stolem myslíme jakýkoli stůl, který je zanesen do rezervačního systému DESKEE a lze si ho tak v aplikaci zarezervovat.
      </Text>
      <Heading
        as="h2"
        size="4xl"
      >Jak DESKEE stůl poznáš?
      </Heading>
      <Text
        fontSize="xl"
        maxW={{ sm: '90%', md: '70%' }}
        textAlign="justify"
      >
        Každý stůl v rezervačním systému DESKEE je označen tzv. DESKEE POINTem. Nejedná se o nic složitého, prostě cedulka na každém stole.
        Cedulka obsahuje jméno stolu pro jeho lepší nalezení a pak na ní nalezneš QR kód nebo NFC nálepku pro rychlou rezervaci, když ti zrovna stůl padne do oka.<br /><br />
        <Text as="i">PS: cedulky jsou vytištěny na 3D tiskárně a k tisku byl použit filament z recyklovaného plastu</Text>
      </Text>
      <Image
        src={deskeePointImgUrl}
        maxW={{ sm: '100%', md: '600px' }}
      />

      <Heading as="h2" size="4xl">Cedulka zelená, cedulka žlutá…</Heading>
      <Text
        fontSize="xl"
        maxW={{ sm: '90%', md: '70%' }}
        textAlign="justify"
      >
        Aby toho nebylo málo, tak každý sdílený stůl může být ve dvou režimech. <br /><br />
        <strong>Režim ANYONE</strong> je značen zelenou cedulkou. To znamená, že každý, kdo jde kolem a stůl naskenuje, (a ten  již není rezervován) může ke stolu usednout. <br /><br />
        Pak je tu <strong>režim GROUP</strong> a jak název napovídá, slouží pro určité skupiny.
        Můžeme to přirovnat ke Google skupinám, buď v ní uživatel je, a nebo není - easy.
        Cílem tohoto režimu je mít větší možnost toku `&ldquo;`přísedících`&ldquo;`. <br /><br />
        Příkladem může být projektová skupina. Máte konkrétní projekt, na který chcete mít u sebe lidi, kteří spolu potřebují něco řešit?
        Bylo by zbytečné, aby v kanceláři seděl někdo, vám s projektem nemůže pomoci a zároveň člověk, co by mohl být užitečný, musel sedět v jíné kancláři nebo dokonce na jiném patře.
        A takto to může fungovat například s týmovou skupinou, skupinou
      </Text>
      <Heading as="h2" size="4xl">Kdy mají stoly večerku?</Heading>
      <Text
        fontSize="xl"
        maxW={{ sm: '90%', md: '70%' }}
        textAlign="justify"
      >
        I stoly si musí oddechnout a začít se těšit na nové přísedící.<br />
        Proto je každá rezervace daného dne v 18:00 a ve 23:59 automaticky smazána.
      </Text>
      <Heading as="h2" size="4xl">Co je to ta předrezervace?</Heading>
      <Text
        fontSize="xl"
        maxW={{ sm: '90%', md: '70%' }}
        textAlign="justify"
      >
        Díky předrezervaci si můžete rezervovat stůl už den dopředu.<br />
        Nestane se ti tak, že přijedeš do kanceláře a tvůj oblíbený stůl už zabral někdo z rychlejších kolegů.<br /><br />
        Každý uživatel může mít pouze jednu aktivní předrezervaci na následující den.
      </Text>
      <Heading as="h2" size="4xl">Chci aplikaci na plochu</Heading>
      <Text
        fontSize="xl"
        maxW={{ sm: '90%', md: '70%' }}
        textAlign="justify"
      >
        Zde je návod jak si přidat DESKEE na plochu svého iOS zařízení a využívat vlastnosti PWA aplikací. Tedy například, vzhled jako nativní aplikace.
      </Text>
      <Button w="200px" leftIcon={<FiRefreshCcw />} variant="solid" onClick={forceRefreshApp}>
        Vynutit aktualizaci verze
      </Button>
      <Image
        src={deskeeIosImgUrl}
        maxW={{ sm: '100%', md: '800px' }}
      />
      <Heading as="h2" size="4xl">Co když nemám účet?</Heading>
      <Text
        fontSize="xl"
        maxW={{ sm: '90%', md: '70%' }}
        textAlign="justify"
      >
        Do systému se můžeš zaregistrovat sám na stránce <Link href="/registrace" textDecor="underline">/registrace</Link><br /><br />
        Pro získání skupin k rezervaci stolů v režimu GROUP kontaktuj svého Team Leadera, který ti ho nechá skupiny přiřadit.<br /><br />
        Registrace jsou povoleny pro emaily s koncovkou etnetera.cz, etnetera.eu, activate.cz, motion.cz, greencode.cz
      </Text>
      <Heading as="h2" size="4xl">Mám takový problém...</Heading>
      <Text
        fontSize="xl"
        maxW={{ sm: '90%', md: '70%' }}
        textAlign="justify"
      >
        Pokud máš nějaký problém s aplikací, nebo prostě jen máš chuť se pobavit o nějakém zlepšováku, neváhej nám napsat!<br /><br />
        Stížnosti i pochvaly syp sem <Link href="mailto:jakub.frydrych@etnetera.cz?subject=[DESKEE] - schránka důvěry" textDecor="underline">na tento email</Link><br /><br />
        Nezapomeň, že aplikace je navržena pro prohlížeč Google Chrome a mobilní Safari iOS
      </Text>
    </VStack>
  );
}

export default HowWorksScreen;
